<script>
import Recorder from '@/shared/components/Recorder.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddCardModel from '@/modules/tags/add-card-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import i18n from '../plugins/vue-i18n';

const { fields } = AddCardModel;
const store = {
  state: {
    optionsLearningSteps: [],
    learningStep: {},
    type: 'flash',
    questionTitle: '',
    answers: [{
      answer: '',
      is_right_answer: true,
    }],
    tagTitle: '',
    categories: [],
    categorySelected: '',
    tags: [],
    method: 'manual',
    limitGenerateCards: 0,
    usedGenerateCards: 0,
    topics: [],
    topicSelected: '',
  },
};
export default {
  name: 'AddCart',
  components: {
    BaseButton,
    Recorder,
  },
  props: {
    tagTitle: {
      type: String,
      required: true,
      default: '',
    },
    cardId: {
      type: Number,
      required: true,
      default: 0,
    },
    info: {
      type: Object,
    },
    withAi: {
      type: Boolean,
      required: true,
      default: false,
    },
    showAiMethod: {
      type: Boolean,
      required: true,
      default: false,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      store,
      model: {},
      Img: [],
      loading: false,
      title: this.tagTitle ?? '',
      id: null,
    };
  },
  methods: {
    Notify() {
      return Notify;
    },
    getLimits() {
      const listCatPath = '/WebGetLimits.php';
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.limits && json.limits.limit_generate_cards) {
            store.state.limitGenerateCards = json.limits.limit_generate_cards;
            store.state.usedGenerateCards = json.limits.used_generate_cards;
          }
        });
    },
    openaiSuggestTagTopics() {
      if (this.tagTitle) {
        const listCatPath = '/WebOpenaiSuggestTagTopics.php';

        const data = new FormData();
        data.append('tag', this.tagTitle);
        fetch(server + listCatPath, {
          method: 'POST',
          headers: {
            WebToken: getToken(),
            'Accept-language': window.localStorage.getItem('language'),
          },
          body: data,
        }).then((response) => response.json()).then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.topics) {
            store.state.topics = json.topics;
          }
        });
        this.loading = false;
      }
    },
    ListCategories() {
      store.state.categories = [];
      const listCatPath = '/WebListTagCategories.php';
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.categories && json.categories.length) {
            store.state.categories = json.categories;
          }
          this.searchTags();
        });
    },
    searchTags() {
      store.state.tags = [];
      const listTagsPath = '/WebListTags.php';
      const data = new FormData();
      data.append('search', '');
      if (store.state.categorySelected !== '') {
        data.append('category_id', store.state.categorySelected.id);
      }
      fetch(server + listTagsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            }
          }
          if (json.tags && json.tags.length) {
            json.tags.forEach((item) => {
              this.store.state.tags.push({
                id: item.id,
                title: item.tag,
              });
            });
          } else {
            store.state.tags = [];
          }
        });
    },
    openAddCard() {
      const addPollWindow = document.querySelector('.view-item');
      addPollWindow.classList.toggle('show');
      if (addPollWindow.classList.value.includes('hide')) {
        store.state.topics = [];
        addPollWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          store.state.topics = [];
          addPollWindow.classList.toggle('hide');
        }, 300);
      }
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    addCardFunc() {
      this.openAddCard();
      let addCardPath = '';
      const data = new FormData();
      if (parseInt(this.cardId, 10) !== 0) {
        addCardPath = '/WebEditQuestion.php';
        data.append('id', this.cardId);
      } else {
        addCardPath = '/WebAddQuestion.php';
      }
      data.append('question', store.state.questionTitle);
      data.append('type', store.state.type);
      store.state.answers.forEach((answer, i) => {
        data.append(`answers[${i}][answer]`, answer.answer);
        if (answer.right !== undefined) {
          data.append(`answers[${i}][right]`, answer.right);
        } else {
          data.append(`answers[${i}][right]`, answer.is_right_answer);
        }
      });
      if (this.tagTitle || this.title) {
        if (this.title) {
          data.append('tags[]', this.title);
        } else {
          data.append('tags[]', this.tagTitle !== '' ? this.tagTitle : this.title);
        }
      }
      data.append('knowledge[]', store.state.learningStep.value);
      data.append('image', this.Img[0] ? this.Img[0] : '');

      fetch(server + addCardPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
            if (json.status === 'ok') {
              this.callback();
            }
          }
        });
    },
    getCard(id) {
      const getCardPath = '/WebGetQuestionDetails.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + getCardPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.title !== '') {
            store.state.questionTitle = json.title;
            store.state.type = json.type;
            if (json.tags[0]) {
              this.title = json.tags[0].tag;
              store.state.categorySelected = {
                id: json.tags[0].category_id,
                name: json.tags[0].category_name,
              };
            } else {
              this.title = '';
            }
            if (json.image) {
              this.Img[0] = json.image;
            } else {
              this.Img = [];
            }

            if (json.knowledges[0]) {
              this.store.state.learningStep = {
                value: json.knowledges[0].code,
                text: json.knowledges[0].name,
              };
            } else {
              store.state.learningStep = {};
            }

            store.state.answers = json.answers ?? [];
          }
        });
    },
    newAnswer() {
      store.state.answers.push({
        answer: '',
        is_right_answer: false,
      });
    },
    getLearningSteps() {
      store.state.optionsLearningSteps = [];
      const getTagsPath = '/WebSuggestTags.php';
      const data = new FormData();
      data.append('search', '');
      data.append('course_id', '');
      fetch(server + getTagsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.knowledge) {
            json.knowledge.forEach((item, i) => {
              if (i === 0) {
                store.state.learningStep = {
                  value: item.code,
                  text: item.name,
                };
              }
              store.state.optionsLearningSteps.push({
                value: item.code,
                text: item.name,
              });
            });
          }
        });
      this.getLimits();
    },
    select(option) {
      store.state.learningStep = option;
    },
    previewImgs(event) {
      this.files = event.currentTarget.files;
      this.error = '';
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        this.Img = values;
      });
    },
    prepareCard() {
      const getCardPath = '/WebOpenaiPrepareCard.php';
      const data = new FormData();
      data.append('question', this.info.question.message);
      data.append('answer', this.info.answer.message);
      data.append('type', store.state.type);
      store.state.questionTitle = this.info.question.message;

      fetch(server + getCardPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.answers && json.answers[store.state.type]
            && json.answers[store.state.type].answer) {
            store.state.answers = [{
              answer: json.answers[store.state.type].answer,
              is_right_answer: true,
            }];
          } else {
            store.state.answers = [{
              answer: this.info.answer.message,
              is_right_answer: true,
            }];
          }
        });
    },
    generateCards() {
      const listCatPath = '/WebOpenaiGenerateCards.php';
      this.loading = true;

      const data = new FormData();
      data.append('tag', this.tagTitle !== '' ? this.tagTitle : this.title);
      data.append('topic', store.state.topicSelected);
      data.append('type', store.state.type);
      if (store.state.learningStep.value !== 'all') {
        data.append('knowledge', store.state.learningStep.value);
      }

      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
          'Accept-language': window.localStorage.getItem('language'),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
            this.callback();
          }
          this.loading = false;
        });
    },
    checkSubmit() {
      if ((this.tagTitle !== '' || this.title !== '') && this.loading === false) {
        if (store.state.method === 'manual') {
          this.addCardFunc(1);
        } else {
          this.generateCards();
        }
      } else if (store.state.categorySelected === '') {
        Notify('warning', i18n.t('TAGS.SELECT_CATEGORY'));
      } else if (this.tagTitle === '' || this.title === '') {
        Notify('warning', i18n.t('TAGS.SELECT_TAG'));
      }
    },
  },
  beforeMount() {
    if (this.tagTitle) {
      this.title = this.tagTitle;
    }
    store.state.type = 'flash';
    if (this.info && this.info.question && this.info.question.message) {
      this.prepareCard();
      //  store.state.questionTitle = this.info.question.message;
    } else {
      store.state.questionTitle = '';
    }
    if (this.info && this.info.answer && this.info.answer.message) {
      /*    store.state.answers = [{
        answer: this.info.answer.message,
        is_right_answer: true,
      }]; */
    } else {
      store.state.answers = [{
        answer: '',
        is_right_answer: true,
      }];
    }
    if (this.withAi === true) {
      this.openaiSuggestTagTopics();
      this.getLimits();
      store.state.method = 'ai';
    } else {
      store.state.method = 'manual';
    }
    if (this.tagTitle === '') {
      this.ListCategories();
    }
    this.title = this.tagTitle;
    store.state.learningStep = {};
    this.getLearningSteps();
    if (this.cardId !== 0) {
      this.getCard(this.cardId);
    }
  },
  beforeUpdate() {
    if (this.tagTitle) {
      this.title = this.tagTitle;
    }
    if (this.tagTitle !== '' && this.title !== this.tagTitle) {
      this.title = this.tagTitle;
      if (this.withAi === true) {
        this.loading = true;
        store.state.method = 'ai';
        this.openaiSuggestTagTopics();
        this.getLimits();
      } else {
        store.state.method = 'manual';
      }
    }
  },
};
</script>
<template>
  <div class="view-item">
    <div class="add-item-left" v-on:click="openAddCard">
    </div>
    <div class="add-item-window" v-if="this.cardId === 0 ||
     (this.cardId !== 0)" :key="store.state.questionTitle">
      <form class="form" v-on:submit.prevent="() => {
        checkSubmit()
      }" id="add_dep_form" style="width: 100%;">

        <div style="height: 100vh" :key="this.cardId">
          <div class="menu" :key="store.state.questionTitle">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddCard"/>
            <div class="title">
              {{ this.cardId !== 0 ? store.state.questionTitle : $t('TAGS.ADD_CARD') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit"
            >
              <div v-if="this.loading === true">
                <unicon fill="white" height="16" width="16" name="spinner-alt"/>
              </div>
              <div v-if="this.loading === false">
                <unicon fill="white" height="16" width="16" name="save"/>
                {{ $t('GENERAL.SAVE') }}
              </div>
            </BaseButton>
          </div>
          <div class="content">
            <div v-if="showAiMethod === true && this.cardId === 0"
                 :key="(this.withAi ? 'true' : 'false')
             + '_' + store.state.method"
                 style=" justify-content: center; display: flex;
margin-bottom: 20px">
              <div style="width: 90%; display: flex;">
                <div :class="'method ' + (store.state.method === 'manual' ? 'active' : '') "
                     v-on:click="() => {
                       store.state.method = 'manual'
                     }"
                >
                  <div>
                    {{ $t("TAGS.CREATE_MANUAL") }}
                  </div>
                </div>
                <div :class="'method ' + (store.state.method === 'ai' ? 'active' : '') "
                     v-on:click="() => {
                       store.state.method = 'ai'
                       if(tagTitle !== '') {
                         this.openaiSuggestTagTopics();
                         this.getLimits();
                       }
                     }">
                  <div>
                    {{ $t("TAGS.GENERATE_WITH_AI") }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="store.state.method === 'manual'">
              <b-row>
                <b-col>
                  <div class="title-2-bold-20 basic-2">
                    {{ $t('GENERAL.BASIC_INFO') }}
                  </div>
                </b-col>
              </b-row>

              <div v-if="tagTitle === ''"
                   style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div>
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.SELECT_CATEGORY') }}
                    </div>
                    <div class="custom_select">
                      <b-dropdown>
                        <div slot="button-content" class="selected">
                          {{ store.state.categorySelected.name }}
                        </div>
                        <b-dropdown-item
                          :disabled="option.disabled"
                          @click="() => {
                          store.state.categorySelected = option;
                          title = '';
                          searchTags()
                        }"
                          v-for="option in store.state.categories"
                          :key="'sss' + option.id"
                        >
                          {{ option.name }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div :key="store.state.categorySelected ? store.state.categorySelected.name : 'd'"
                   v-if="tagTitle === ''"
                   style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div>
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.SELECT_TAG') }}
                    </div>
                    <div class="custom_select" :key="title ?? 'ddfs'">
                      <b-dropdown aria-required="true">
                        <div slot="button-content" class="selected"
                             v-if="title !== ''">
                          {{title}}
                        </div>
                        <b-dropdown-item
                          :disabled="option.disabled"
                          @click="() => {
                          title = option.title
                        }"
                          v-for="option in store.state.tags"
                          :key="'ddd' + option.title"
                        >
                          {{ option.title }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <b-row>
                <b-col>
                  <div style="margin-left: -12.5px; margin-right: -12.5px">
                    <b-row>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'flash' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'flash';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}]">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.FLASH_CARD') }}
                            <unicon name="swatchbook" height="24" width="24"
                                    fill="#E2445C"/>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'text' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'text';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}]">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.TEXT_ANSWER') }}
                            <unicon name="edit-alt" height="24" width="24"
                                    fill="#04D1AC"/>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'voice' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'voice';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}]">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.VOICE_ANSWER') }}
                            <unicon name="microphone" height="24" width="24"
                                    fill="#FDAB3D"/>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'options' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'options';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}];">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.MULTIPLE_ANSWER') }}
                            <unicon name="list-ul" height="24" width="24"
                                    fill="#1694D0"/>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.QUESTION_TITLE') }}
                    </div>
                    <div class="custom-input recorder">
                    <textarea type="text"
                              :id="store.state.questionTitle"
                              :value="store.state.questionTitle"
                              v-on:change="(e) => store.state.questionTitle = e.target.value"
                              :placeholder="$t('TAGS.FIELDS.QUESTION_TITLE')"
                              required
                              maxlength="200"

                    />

                      <Recorder
                        :onChange="(value,rec) => {
                          store.state.questionTitle = value
                        }"
                      />
                    </div>
                    <div class="upload-question-photo"
                         style="position: absolute;top: 84px;height: 40px;
                      right: 0; width: 40px">
                      <input
                        style="opacity: 0;width: 40px; height: 40px;
                         z-index: 100; position: relative"
                        type="file"
                        accept="image/*"
                        ref="uploadInput"
                        @change="previewImgs"
                        multiple
                      />
                      <unicon style="z-index: 0; position: absolute; top:8px; left:8px"
                              name="image-plus"
                              width="24" height="24" fill="#333333"/>
                    </div>
                  </div>
                  <div style="position: relative" v-if="this.Img.length !== 0">
                    <img :src="this.Img[0]" alt=""
                         style="max-width: 100%;margin-top: 20px"/>
                    <div class="delete-card" v-on:click="Img = []" style="left: unset; right: 0">
                      <unicon name="trash-alt" fill="#BDBDBD" height="18" width="18"/>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px"
                   v-if="store.state.type !== 'options'"
              >
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.ANSWER') }}
                    </div>
                    <div class="custom-input recorder">
                    <textarea type="text"
                              :id="store.state.answers[0]"
                              :value="store.state.answers[0].answer"
                              v-on:change="(e) => store.state.answers[0].answer = e.target.value"
                              :placeholder="$t('TAGS.FIELDS.ANSWER')"
                              required
                              maxlength="200"
                    />

                      <Recorder
                        :onChange="(value,rec) => {
                          store.state.answers[0].answer = value
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px"
                   v-if="store.state.type === 'options'">
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.ANSWER') }}
                    </div>
                  </div>
                  <div class="custom-input" v-for="(answer, j) in store.state.answers" :key="j">
                    <div class="d-flex"
                         style="margin-bottom: 10px">
                      <div class="d-inline-flex" style="width: -webkit-fill-available;">
                        <div class="custom-input">
                          <label v-if="answer.is_right_answer">
                            {{ $t('TAGS.ANSWER_RIGHT') }}
                          </label>
                          <label v-if="!answer.is_right_answer">
                            {{ $t('TAGS.ANSWER_WRONG') }}
                          </label>
                          <div class="custom-input recorder">
                          <textarea type="text"
                                    :id="fields.answer.name"
                                    v-model="answer.answer"
                                    :placeholder="fields.answer.label"
                                    required
                                    maxlength="200"
                          />

                            <Recorder
                              :onChange="(value,rec) => {
                          store.state.answers[0].answer = value
                        }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="d-inline-flex" v-if="j !== 0">
                        <unicon name="trash-alt" height="18" width="18" style="margin: 40px 0 0 8px;
                        cursor: pointer; left: unset;right: 8px;top: -4px"
                                fill="#EB5757" v-on:click="store.state.answers.splice(j,1)"/>
                      </div>
                    </div>
                  </div>
                  <BaseButton style="width: 100%;justify-content: center" variant="light"
                              v-if="store.state.answers.length < 5"
                              :callback="this.newAnswer" :callback-params="0">
                    <unicon name="plus" width="16" height="16"
                            style="margin-right: 12px;margin-top: -2px" fill="#1694D0"/>
                    {{ $t('TAGS.ADD_ANSWER') }}
                  </BaseButton>
                </div>
              </div>

              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div>
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.LEARNING_STEP') }}
                    </div>
                    <div class="custom_select">
                      <b-dropdown>
                        <div slot="button-content" class="selected">
                          <img v-if="store.state.learningStep && store.state.learningStep.value"
                               :key="'1-' + store.state.learningStep.value"
                               :src="'/media/learning_steps/'
                        + store.state.learningStep.value + '.svg'"/>
                          <div v-if="store.state.learningStep && store.state.learningStep.value"
                               :key="'2-' + store.state.learningStep.value"
                               class="d-inline-block float-end">
                            <div class="basic-1 headline-regular-16">
                              {{ store.state.learningStep.text }}
                            </div>
                          </div>
                        </div>
                        <b-dropdown-item
                          :disabled="option.disabled"
                          @click="select(option)"
                          v-for="option in store.state.optionsLearningSteps"
                          :key="'ccc'+option.value"
                        >
                          <div class="options-learning-steps">
                            <img :src="'/media/learning_steps/' + option.value + '.svg' ">
                            <div class="d-inline-block float-end">
                              <div class="basic-1 headline-regular-16">
                                {{ option.text }}
                              </div>
                            </div>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="store.state.method === 'ai'">
              <b-row>
                <b-col>
                  <div class="title-2-bold-20 basic-2"
                       style="margin-right: 40px;display: flex; width: 100%">
                    {{ $t("TAGS.HAVE") }} {{
                      store.state.limitGenerateCards -
                      store.state.usedGenerateCards
                    }} {{ $t("TAGS.FREE_TRIAL") }}
                  </div>
                </b-col>
              </b-row>

              <b-row style=" margin-bottom: 24px">
                <b-col>
                  <div>

                    <div style="display: flex">
                      <div class="title-2-bold-20 basic-2">
                        {{ $t('TAGS.SELECT_TOPICS') }}
                      </div>
                      <BaseButton
                        class="float-end"
                        :callback="openaiSuggestTagTopics"
                        :callback-params="0"
                        :variant="'light'"
                        style="margin-left: auto; height: 33px; padding: 6px!important;"
                        type="button">
                        <unicon fill="#1694D0" height="16" width="16" name="redo" class="me-2"/>
                        {{ $t('GENERAL.CHANGE') }}
                      </BaseButton>
                    </div>
                  </div>

                  <div class="topics-container">
                    <div v-for="(topic, j) in store.state.topics" :key="j" class="topic-container">

                      <input type="radio"
                             v-on:click="store.state.topicSelected = topic"
                             :checked="store.state.topicSelected === topic" class="checkbox"/>
                      <label>
                        {{ topic }}</label>

                    </div>
                  </div>
                </b-col>
              </b-row>

              <div style="display: inline-flex;width: 100%;
                  padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div>
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.LEARNING_STEP') }}
                    </div>
                    <div class="custom_select learning-steps">
                      <b-dropdown>
                        <div slot="button-content" class="selected">
                          <img v-if="store.state.learningStep && store.state.learningStep.value"
                               :key="'1-' + store.state.learningStep.value"
                               :src="'/media/learning_steps/'
                        + store.state.learningStep.value + '.svg'"/>
                          <div v-if="store.state.learningStep && store.state.learningStep.value"
                               :key="'2-' + store.state.learningStep.value"
                               class="d-inline-block float-end">
                            <div class="basic-1 headline-regular-16">
                              {{ store.state.learningStep.text }}
                            </div>
                          </div>
                        </div>
                        <b-dropdown-item
                            @click="select({
                            value: 'all',
                            text:  $t('TAGS.ALL_STEPS')
                    })"
                            key="all"
                            v-if="store.state.method === 'ai'"
                        >
                          <div class="options-learning-steps">
                            <img :src="'/media/learning_steps/all.svg' ">
                            <div class="d-inline-block float-end">
                              <div class="basic-1 headline-regular-16">
                                {{ $t('TAGS.ALL_STEPS') }}
                              </div>
                            </div>
                          </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :disabled="option.disabled"
                          @click="select(option)"
                          v-for="option in store.state.optionsLearningSteps"
                          :key="option.value"
                        >
                          <div class="options-learning-steps">
                            <img :src="'/media/learning_steps/' + option.value + '.svg' ">
                            <div class="d-inline-block float-end">
                              <div class="basic-1 headline-regular-16">
                                {{ option.text }}
                              </div>
                            </div>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <b-row>
                <b-col>
                  <div class="title-2-bold-20 basic-2">
                    {{ $t('TAGS.SELECT_CARD_TYPE') }}
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div style="margin-left: -12.5px; margin-right: -12.5px">
                    <b-row>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">

                        <div :class="'type-selector' + (store.state.type === 'flash' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'flash';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}]">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.FLASH_CARD') }}
                            <unicon name="swatchbook" height="24" width="24"
                                    fill="#E2445C"/>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'text' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'text';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}]">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.TEXT_ANSWER') }}
                            <unicon name="edit-alt" height="24" width="24"
                                    fill="#04D1AC"/>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'voice' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'voice';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}]">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.VOICE_ANSWER') }}
                            <unicon name="microphone" height="24" width="24"
                                    fill="#FDAB3D"/>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="6" style="padding-bottom: 24px">
                        <div :class="'type-selector' + (store.state.type === 'options' ?
                      ' active' : '')"
                             v-on:click="store.state.type = 'options';
                      prepareCard();
                      store.state.answers = [{answer: this.info && this.info.answer &&
                      this.info.answer.id ? this.info.answer.message : '',
                      is_right_answer: true}];">
                          <div class="headline-regular-16 basic-2">
                            {{ $t('TAGS.MULTIPLE_ANSWER') }}
                            <unicon name="list-ul" height="24" width="24"
                                    fill="#1694D0"/>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
.view-item .delete-card {
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 28px;
}

.view-item .type-selector {
  background: #FFFFFF;
  box-shadow: 0px 0px 10.5622px rgba(0, 0, 0, 0.2);
  border-radius: 9.38863px;
  width: 100%;
  height: 124px;
  padding: 24px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16.4301px;
  line-height: 21px;
  text-align: center;
}

.view-item .type-selector .unicon {
  display: block;
}

.view-item .type-selector svg {
  margin-top: 8px;
  margin-right: 0;
}

.view-item .type-selector.active {
  border: 1.17358px solid #04D1AC;
}

.view-item .custom_select.learning-steps .dropdown-item {
  padding-bottom: 0;
}

.options-learning-steps {
  border-bottom: 1px solid #E0E0E0;
}

.view-item .custom_select button, .view-item .custom_select .dropdown.b-dropdown.btn-group {
  margin-top: 0;
  height: 43px !important;
}

.options-learning-steps img, .view-item .custom_select img {
  position: unset;
  height: 17px !important;
  margin-top: 0 !important;
}

.upload-question-photo {
  position: absolute;
  right: 8px;
}

.upload-question-photo input {
  width: 24px;
  background: transparent;
}

.upload-question-photo .unicon {
  position: absolute;
  top: -65px;
  left: -24px;
}

.method {
  color: #828282;
  border-bottom: #E0E0E0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  width: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
}

.method.active {
  color: #333333;
  border-bottom: #1694D0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.topics-container {
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 0 10px;

  .topic-container {
    display: flex;
    align-items: center;
    vertical-align: center;
    border-bottom: 1px solid #E0E0E0;
    font-size: 14px;
    padding: 12px 0;
  }

  .topic-container:last-child {
    border-bottom: none;
  }
}

.topic-container input {
  height: 20px;
  width: 20px;
  margin: 0 10px 0 0;
}
</style>
